import Layout from "./layouts";
import Checkout from "./pages/Checkout";
import Waitlist from "./pages/Waitlist";
import Landing from "./pages/Landing";
import SignupError from "./pages/SignupError";
import Pioneer from "./pages/Pioneer";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/signup-error" element={<SignupError />} />
          <Route path="/pioneer" element={<Pioneer />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;