import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import Topbar from "./Topbar";
import useWindowDimensions from "../hooks/useDimensions";

const Layout = ({ children }) => {
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  const loc = useLocation()
  const [openMenu, setOpenMenu] = useState(false);
  useMemo(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if(screenWidth > 0 && screenHeight > 0) appHeight()
  }, [screenWidth, screenHeight])
  return (
    <div className="flex flex-col min-h-full relative">
      <main className="flex flex-col flex-1">{children}</main>
      {loc.pathname !== "/checkout" && loc.pathname !== "/signup-error" && loc.pathname !== "/pioneer" && 
        <>
          <Topbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
          <div className={`block md:hidden fixed z-[4] w-full h-full bg-menuback transition-all pointer-events-none	${openMenu && "backdrop-blur-sm pointer-events-auto"}`} />
        </>
      }
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
