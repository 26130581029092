import React, { useState, useMemo } from "react";
import axios from '../utilities/axiosConfig';

const Waitlist = () => {
  const [joined, setJoined] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [totalJoined, setTotal] = useState(0);
  const [email, setEmail] = useState("");
  const [joining, setJoining] = useState(false);
  const join = async () => {
    if(email === "" || loaded === false) return;
    setJoining(true);
    try {
      await axios.post("/waitlist", {
        email
      })
      setJoined(true);
    } catch (e) {
      console.error('Error: ', e);
    }
    setJoining(false);
  }

  useMemo(async () => {
    const res = await axios.put("/waitlist/recently");
    if (res.data?.count > 0) {
      setJoined(true);
    }
    setTotal(res.data?.total || 0);
    setLoaded(true);
  }, [])
  return (
    <div className="mt-[192px] md:mt-[182px] waitlist">
      <div className="flex flex-col items-center relative z-[2]">
        <img className="w-[57px] md:w-auto" src="images/logo_big.svg" alt="" />
        <div className="mt-[19px] md:mt-9 title font-semibold text-center">
          Get <span className="gradient-text bg-fire">Early Access</span>
          <br />
          To Prive
        </div>
        {joined === true &&
          <>
            <span className="hidden md:block mt-5 text-[14px] md:text-[1.3888vw] leading-[18px] md:leading-[1.9444vw] text-center">You have been added to the waitlist & will get<br/>an email once you are granted access to Prive.</span>
            <span className="block md:hidden mt-5 text-[14px] md:text-[1.3888vw] leading-[18px] md:leading-[1.9444vw] text-center">You have been added to the waitlist &<br /> will get an email once you are granted<br /> access to Prive.</span>
            <a className="flex justify-center items-center space-x-2 cursor-pointer mt-[54px] md:mt-[47px] w-[175px] h-[54.89px] md:w-[191.3px] md:h-[60px] bg-white rounded-[5px] twitter-follow" href="http://twitter.com/privemobile" >
              <img className="w-[40.25px] h-[31.1px] md:w-[44px] md:h-[34px]" src="images/twitter.svg" alt="" />
              <div className="flex flex-col items-center text-black">
                <span className="font-semibold text-[13.27px] md:text-[14.5px] leading-[100%]">FOLLOW US ON</span>
                <span className="font-black text-[21.31px] md:text-[23.3px] leading-[100%]">TWITTER</span>
              </div>
            </a>
          </>
        }
        {joined === false &&
          <>
            <div className="mt-5 md:mt-[30px] text-[14px] md:text-[1.3888vw] leading-[18px] md:leading-[1.9444vw] text-center max-w-[311px] md:max-w-[48.4027vw] font-medium">
              Join the waitlist and we'll notify you when Prive goes live in the United States. We'll also email you the latest Prive news & updates.
            </div>
            <div className="flex flex-col items-start mt-[30px] md:mt-[35px] space-y-[6px] max-w-[calc(100%-48px)] md:max-w-unset">
            {
              loaded &&
              <span className="text-[12px] md:text-[0.9722vw] leading-[14.32px] md:leading-[1.1805vw] font-semibold md:font-medium">Join {999 + totalJoined} others on the waitlist</span>
            }
            <div className="px-[1px] py-[1px] md:py-[2px] bg-fire rounded-tiny">
              <div className="flex items-center bg-white rounded-tiny text-[13px] md:text-[0.9722vw] leading-[15.51px] md:leading-[1.1604vw]">
                <input className="text-[14px] md:text-[0.97222vw] leading-[16.71px] md:leading-[1.1604vw] px-[11px] md:px-[1.4583vw] text-black w-[217px] md:w-[18.8888vw]" placeholder="Enter your email address"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />
                <button className="flex justify-center items-center w-[110px] md:w-[10vw] py-3 md:py-[1.1111vw] bg-sand rounded-tiny dark agree-button" onClick={join} disabled={joining}>
                  <div>
                    {joining || !loaded ?
                      <img className="prive-loading1" src="images/spinner2.png" alt="" />
                      :
                      <span className="font-bold text-[14px] md:text-[0.9722vw] leading-[16.71px] md:leading-[1.1604vw] tracking-[0.2px] gradient-text bg-white">Join Waitlist</span>
                    }
                  </div>
                </button>
              </div>
            </div>
          </div>
          </>
        }
      </div>
      <img className="hidden md:block fixed left-1/2 bottom-0 -translate-x-1/2 min-w-[1800px] w-full" src="images/wait_back.png" alt="" />
      <img className="block md:hidden fixed left-1/2 bottom-0 -translate-x-1/2 w-full" src="images/mobile_wait_back.png" alt="" />
    </div>
  );
};

export default Waitlist;