import React from "react";
import { useNavigate } from "react-router-dom";

const SignupError = () => {
  const nav = useNavigate();
  return (
    <div className="checkout">
      <button className="absolute bottom-[71.08px] left-1/2 -translate-x-1/2 p-[1px] bg-buttonback w-[324.49px] h-[45.92px] text-black text-[14px] leading-[17px] lg:text-[18px] lg:leading-[21px] font-semibold hover:text-white agree-button rounded-[22.5px] 2xl:rounded-[1.5625vw] fire-button disabled:bg-grey3 disabled:text-grey1 fire-button z-[2]" onClick={() => nav("/")}>
        <div className="flex justify-center items-center bg-fire w-full h-full relative z-[2] rounded-[22.5px] 2xl:rounded-[1.5625vw] space-x-2">
          <img className="arrow-right rotate-180" src="images/arrow-black.svg" alt="" />
          <img className="arrow-right-hover rotate-180" src="images/arrow.svg" alt="" />
          <span>Go back to site</span>
        </div>
        <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
      </button>
      <div className="flex flex-col items-center mt-[90px] px-[25px] relative z-[2]">
        <span className="font-semibold text-[30px] md:text-[45px] leading-[35.8px] md:leading-[53.7px] text-center">We'll Be Right Back</span>
        <span className="mt-[18px] font-semibold text-[18px] md:text-[20px] leading-[22.74px] md:leading-[25.27px] text-center">Due to high traffic, we are unable to activate your line at this time.</span>
        <span className="mt-2 text-[14px] leading-[20px] md:leading-[24px] text-center">If you have been charged, expect a full refund in a few business days. We are sorry for the inconvenience.</span>
      </div>
      {/* <img className="hidden md:block absolute right-1/2 bottom-[0px] translate-x-[55%] z-[1]" src="images/phone-lost.png" alt="" /> */}
      <img className="absolute right-1/2 translate-x-1/2 z-[1] w-[520px] min-w-[520px]" style={{ bottom: "0px"}} src="images/phone-lost-mobile.png" alt="" />
      <img className="hidden md:block fixed left-1/2 bottom-0 -translate-x-1/2 min-w-[1800px] w-full" src="images/wait_back.png" alt="" />
      <img className="md:hidden fixed left-1/2 bottom-0 -translate-x-1/2 w-full" src="images/mobile-checkout-back.png" alt="" />
      <div className="flex md:hidden justify-center items-center fixed top-0 left-0 bg-black h-[86px] px-[25px] w-full z-[3]">
        <div className="flex items-center logo">
          <img className="w-[18px] h-[22px]" src="images/logo.svg" alt="" />
          <span className="ml-[9px] font-semibold text-[20px] leading-[23.87px]">PRIVE</span>
        </div>
      </div>
    </div>
  );
};

export default SignupError;