import React, { useContext, useEffect } from "react";
import { Fade, Slide } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RefContext } from "../contexts/RefContextProvider";

const Topbar = ({ openMenu, setOpenMenu }) => {
  const nav = useNavigate()
  const loc = useLocation()
  const { headerRef, shopPlansRef, familyPlansRef, whyPriveRef, footerRef, showIntroduceContents } = useContext(RefContext);
  const navigate = async (ref) => {
    nav("/")
    if (loc.pathname === "/") {
      var headerOffset = 150;
      var elementPosition = ref.current.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        // behavior: "smooth"
      });
      setOpenMenu(false);
    }
  }
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [])
  return (
    <header className="fixed lg:absolute w-full z-[6] bg-black" ref={headerRef}>
      <Slide direction="down" in={showIntroduceContents || loc.pathname !== "/"}>
        <div className="flex justify-between items-center ml-auto mr-auto px-6 py-[33px] mobile:px-[125px] mobile:pt-[55px] mobile:pb-[35px] relative">
          <Link to="/">
            <div className="flex items-center logo">
              <img className="w-[18px] h-[22px] mobile:w-auto mobile:h-auto" src="images/logo.svg" alt="" />
              <span className="ml-[9px] font-bold text-[20px] leading-[23.87px] mobile:font-semibold mobile:text-[28px] mobile:leading-[33.41px]">PRIVE</span>
            </div>
          </Link>
          <div className="md:flex items-center space-x-[39px] 2xl:space-x-[2.7083vw] hidden">
            <span className="cursor-pointer text-[15px] leading-[18px] 2xl:text-[1.0416vw] 2xl:leading-[1.25vw] hover:text-blue1 transition-all" onClick={() => navigate(shopPlansRef)}>Shop Plans</span>
            <span className="cursor-pointer text-[15px] leading-[18px] 2xl:text-[1.0416vw] 2xl:leading-[1.25vw] hover:text-blue1 transition-all" onClick={() => navigate(familyPlansRef)}>Family Plans</span>
            <span className="cursor-pointer text-[15px] leading-[18px] 2xl:text-[1.0416vw] 2xl:leading-[1.25vw] hover:text-blue1 transition-all" onClick={() => navigate(whyPriveRef)}>Why Prive</span>
            <div className="cursor-pointer flex items-center space-x-[3px] hover:text-blue1 transition-all">
              <span className="text-[15px] leading-[18px] 2xl:text-[1.0416vw] 2xl:leading-[1.25vw]" onClick={() => navigate(footerRef)}>Help</span>
              <img src="images/arrow-down.svg" alt="" />
            </div>
            <Link to="/checkout" className="relative cart">
              <img className="cursor-pointer relative 2xl:w-[2.2222vw] 2xl:h-[2.2222vw]" src="images/shopping-cart.svg" alt="" />
              <img className="absolute blur-sm w-[120%] h-[120%] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity" src="images/shopping-cart.svg" alt="" />
            </Link>
          </div>
          <div className="flex flex-col items-end gap-y-1 md:hidden cursor-pointer relative" onClick={() => setOpenMenu(!openMenu)}>
            <div className={`absolute bg-fire w-[30px] h-[5px] rounded-[2.5px] transition-all origin-top-right ${openMenu && "-rotate-45 translate-x-[-12px]"}`} />
            <div className={`bg-fire w-[30px] h-[5px] rounded-[2.5px] transition-all origin-top-left ${openMenu && "rotate-45"}`} />
            <div className={`bg-fire w-[24px] h-[5px] rounded-[2.5px] transition-all ${openMenu && "opacity-0"}`} />
            <div className={`bg-fire w-[16px] h-[5px] rounded-[2.5px] transition-all ${openMenu && "opacity-0"}`} />
          </div>
          <Fade in={openMenu}>
            <div className="flex md:hidden flex-col items-center space-y-[30px] py-10 absolute top-full left-0 w-screen bg-black">
              <span className="cursor-pointer text-[18px] leading-[21.48px] hover:text-blue1 transition-all" onClick={() => navigate(shopPlansRef)}>Shop Plans</span>
              <span className="cursor-pointer text-[18px] leading-[21.48px] hover:text-blue1 transition-all" onClick={() => navigate(familyPlansRef)}>Family Plans</span>
              <span className="cursor-pointer text-[18px] leading-[21.48px] hover:text-blue1 transition-all" onClick={() => navigate(whyPriveRef)}>Why Prive</span>
              <span className="cursor-pointer text-[18px] leading-[21.48px] hover:text-blue1 transition-all" onClick={() => navigate(footerRef)}>Help</span>
              <Link to="/checkout" className="relative cart">
                <img className="cursor-pointer" src="images/shopping-cart.svg" alt="" />
                <img className="absolute blur-sm w-[120%] h-[120%] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity" src="images/shopping-cart.svg" alt="" />
              </Link>
            </div>
          </Fade>
        </div>
      </Slide>
    </header>
  );
};

export default Topbar;
