import { createContext, useRef, useState } from "react";

export const RefContext = createContext(null);

export default function RefContextProvider({ children }) {
  const headerRef = useRef(null);
  const shopPlansRef = useRef(null);
  const familyPlansRef = useRef(null);
  const whyPriveRef = useRef(null);
  const footerRef = useRef(null);
  const [showIntroduceContents, setShowIntroduceContents] = useState(true)
  return <RefContext.Provider value={{ headerRef, shopPlansRef, familyPlansRef, whyPriveRef, footerRef, showIntroduceContents, setShowIntroduceContents }}>{children}</RefContext.Provider>
};